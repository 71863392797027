export default [
    {
        path:'/merchant_store/login',
        component:()=>import('@/view/merchant_store/login.vue'),
    },
    {
        path:'/merchant_store',
        component:()=>import('@/view/merchant_store/index.vue'),
        meta:{requiresAuth: true},
        children: [
            {
                path:'home',
                component:()=>import('@/view/merchant_store/home/index.vue'),
                meta:{requiresAuth: true},
            },
            {
                path:'pay_order',
                component:()=>import('@/view/merchant_store/pay_order/index.vue'),
                meta:{requiresAuth: true},
            },
            {
                path:'reservation_meal',
                component:()=>import('@/view/merchant_store/reservation_meal/list.vue'),
                meta:{requiresAuth: true},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/merchant_store/reservation_meal/list.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path:'food_list',
                        component:()=>import('@/view/merchant_store/reservation_meal/food_list.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path:'food_menu_plan',
                        component:()=>import('@/view/merchant_store/reservation_meal/food_menu_plan.vue'),
                        meta:{requiresAuth: true},
                    },
                ]
            },
        ]
    },
]