export default [
    {
        path:'/common',
        component:()=>import('@/view/common/index.vue'),
        children: [
            {
                path:'alipay',
                component:()=>import('@/view/common/alipay/index.vue'),
                children: [
                    {
                        path:'member',
                        component:()=>import('@/view/common/alipay/member/index.vue'),
                        children: [
                            {
                                path:'shop',
                                component:()=>import('@/view/common/alipay/member/shop.vue'),
                            },
                            {
                                path:'content',
                                component:()=>import('@/view/common/alipay/member/content.vue'),
                            },
                        ]
                    }
                ]
            },
            {
                path:'payment',
                component:()=>import('@/view/common/payment/index.vue'),
                children: [
                    {
                        path:'payment_qrcode',
                        component:()=>import('@/view/common/payment/payment_qrcode.vue'),
                    }
                ]
            }
        ]
    },
]