export default [
    {
        path:'/admin/login',
        component:()=>import('@/view/admin/login.vue'),
        meta:{title: "服务端登录"}
    },
    {
        path:'/admin',
        component:()=>import('@/view/admin/index.vue'),
        meta:{requiresAuth: true,title:''},
        children: [
            {
                path:'home',
                component:()=>import('@/view/admin/home/index.vue'),
                meta:{requiresAuth: true,name:'首页'},
            },
            {
                path:'user',
                component:()=>import('@/view/admin/user/index.vue'),
                meta:{requiresAuth: true,name:'用户管理'},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/admin/user/list.vue'),
                        meta:{requiresAuth: true,name:'用户列表'},
                    }
                ]
            },
            {
                path:'facility',
                component:()=>import('@/view/admin/facility/index.vue'),
                meta:{requiresAuth: true,title:'设备管理'},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/admin/facility/list.vue'),
                        meta:{requiresAuth: true,title:'设备列表'},
                    }
                ]
            },
            {
                path:'merchant',
                component:()=>import('@/view/admin/merchant/index.vue'),
                meta:{requiresAuth: true,title:'商户管理'},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/admin/merchant/list.vue'),
                        meta:{requiresAuth: true,title:'商户列表'},
                    }
                ]
            },
            {
                path:'merchant_store',
                component:()=>import('@/view/admin/merchant_store/index.vue'),
                meta:{requiresAuth: true,title:'店铺管理'},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/admin/merchant_store/list.vue'),
                        meta:{requiresAuth: true,title:'店铺列表'},
                    }
                ]
            },
            {
                path:'merchant_entry',
                component:()=>import('@/view/admin/merchant_entry/index.vue'),
                meta:{requiresAuth: true,title:'进件管理'},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/admin/merchant_entry/list.vue'),
                        meta:{requiresAuth: true,title:'进件列表'},
                    }
                ]
            },
            {
                path:'pay_order',
                component:()=>import('@/view/admin/pay_order/index.vue'),
                meta:{requiresAuth: true,title:'订单管理'},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/admin/pay_order/list.vue'),
                        meta:{requiresAuth: true,title:'订单列表'},
                    }
                ]
            },
            {
                path:'alipay_enterprise',
                component:()=>import('@/view/admin/alipay_enterprise/index.vue'),
                meta:{requiresAuth: true,title:'支付宝企业码'},
                children:[
                    {
                        path:'staff',
                        component:()=>import('@/view/admin/alipay_enterprise/staff.vue'),
                        meta:{requiresAuth: true,title:'员工'},
                    },
                    {
                        path:'department',
                        component:()=>import('@/view/admin/alipay_enterprise/department.vue'),
                        meta:{requiresAuth: true,title:'部门'},
                    },
                    {
                        path:'enterprise',
                        component:()=>import('@/view/admin/alipay_enterprise/enterprise.vue'),
                        meta:{requiresAuth: true,title:'企业管理'},
                    },
                    {
                        path:'institution',
                        component:()=>import('@/view/admin/alipay_enterprise/institution.vue'),
                        meta:{requiresAuth: true,title:'制度管理'},
                    },
                    {
                        path:'fee_use_rule',
                        component:()=>import('@/view/admin/alipay_enterprise/fee_use_rule.vue'),
                        meta:{requiresAuth: true,title:'费控规则'},
                    },
                    {
                        path:'quota_control',
                        component:()=>import('@/view/admin/alipay_enterprise/quota_control.vue'),
                        meta:{requiresAuth: true,title:'内算额度管理'},
                    },
                    {
                        path:'auto_limit',
                        component:()=>import('@/view/admin/alipay_enterprise/auto_limit.vue'),
                        meta:{requiresAuth: true,title:'内算自动额度发放'},
                    },
                    {
                        path:'enterprise_invoice',
                        component:()=>import('@/view/admin/alipay_enterprise/enterprise_invoice.vue'),
                        meta:{requiresAuth: true,title:'企业抬头'},
                    },
                    {
                        path:'enterprise_invoice_rule',
                        component:()=>import('@/view/admin/alipay_enterprise/enterprise_invoice_rule.vue'),
                        meta:{requiresAuth: true,title:'企业抬头规则'},
                    },
                ]
            },
            {
                path:'set_up',
                component:()=>import('@/view/admin/set_up/index.vue'),
                meta:{requiresAuth: true},
                children:[
                    {
                        path:'auth_menu',
                        component:()=>import('@/view/admin/set_up/auth_menu.vue'),
                        meta:{requiresAuth: true,title:'权限菜单'},
                    },
                    {
                        path:'role_list',
                        component:()=>import('@/view/admin/set_up/role_list.vue'),
                        meta:{requiresAuth: true,title:'角色管理'},
                    },
                    {
                        path:'pay_access_list',
                        component:()=>import('@/view/admin/set_up/pay_access_list.vue'),
                        meta:{requiresAuth: true,title:'支付渠道管理'},
                    },
                    {
                        path:'config',
                        component:()=>import('@/view/admin/set_up/config.vue'),
                        meta:{requiresAuth: true,title:'系统配置'},
                    },
                ]
            },

        ]
    }
]