import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        login_info:{
            jwt:"",
            name:"",
            account_number:"",
            id:"",
            area_id:"",
            verify:1
        },
        system_info:{
            base_url:"",
            upload_file:"",
            upload_image:"",
            wss:"",
        },
        client_data:{
            id:"",
            name:"时知团餐系统",
            create_time:"",
            update_time:"",
            delete_time:"",
            host:"",
            summit_id:"",
            agent_id:"",
            merchant_id:"",
            iot_images:"",
            logo:"",
            filings:"",
        },
        router:"",
        jwt:"",
        out_url:"",
        login_form:{
            account_number:"",
            password:"",
            record:"",
            captcha_code:'',
            captcha_key:'',
        },
        merchant_info:{
            id:null,
            store_name:null,
        },
        merchant_entry:{
            id:null,
            merchant_id:null
        },
        self_info:{
            id:null,
            account_number:null,
            name:null,
            picture:null,
            last_ip:null,
            agent_id:null,
            pid:null,
            summit_id:null,
            status:null,
            user_id:null,
            role_id:null,
            role_pid:null,
            password_num:null,
            sex:null,
            sellers_id:null,
        },
        show_menu_list:[]
    },
    mutations:{//处理同步数据
        updateLoginInfo(state,data){
            //state.loginInfo = JSON.parse(JSON.stringify(data))
            for(let key in data){
                state.login_info[key] = data[key]
            }
            state.jwt = data['jwt']
        },
        updateLoginForm(state,payload){
            state.login_form = payload
        },
        clearLogin(state){
            state.login_info = {
                jwt:"",
                name:"",
                account_number:"",
                id:"",
            }
            state.jwt = ""
        },
        updateToken(state,payload){
            state.login_info = payload
            state.jwt = payload['jwt']
        },
        updateSelf(state,payload){
            state.self_info = payload
        },
        updateSystemInfo(state,payload){
            state.system_info = payload
        },
        updateMerchantInfo(state,payload){
            state.merchant_info = payload
        },
        updateMerchantEntry(state,payload){
            state.merchant_entry = payload
        },
        updateOutUrl(state,payload){
            state.out_url = payload
        }
    },
    actions:{ //处理异步数据
        asyncSetLoginInfo({commit} , data){ //异步设置登录信息
            commit("updateLoginInfo",data)
        },
        asyncSetLoginForm({commit} , data){
            commit("updateLoginForm",data)
        },
        asyncSetOutUrl({commit} , data){
            commit("updateOutUrl",data)
        },
        asyncUpdateToken({commit} , data){
            commit("updateToken",data)
        },
        asyncUpdateSelf({commit} , data){
            commit("updateSelf",data)
        }
    },
    plugins:[
        createPersistedstate({
            // key:'loginInfo',
            paths:['login_info','login_form','client_data','jwt','router','merchant_info','merchant_entry','out_url','self_info']
        })
    ]
});
