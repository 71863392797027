import {Notification } from 'element-ui'
import {systemInfo} from "@/config/serve_config"
import store from "@/store/index";
export default {
    status:false,
    ws: {},
    //发送ws方法
    sendWs: function(data) {
        //console.log(data, '发送的消息')
        if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
            // Notification({
            //     title:"wss",
            //     message:"通信已断开!",
            //     type:'error'
            // })
            //wss 处于活跃再重启
            if( document.visibilityState==="visible"){
                this.bindWs()
            }
            return;
        }
        this.ws.send(JSON.stringify(data))
    },
    //初始化ws
    initWs: function() {
        this.status = false
        const that = this
        const ws = that.ws


        if ('WebSocket' in window) {
            // 打开一个 web socket
            const ws = new WebSocket(systemInfo.wss)

            that.ws = ws
            ws.onopen = function() {
                //console.log('ws.onopen')
                // Web Socket 已连接上，使用 that.sendWs() 方法发送数据, 例如
                // Notification({
                //     title:"wss",
                //     message:"已连接",
                //     type:'success'
                // })
                that.sendWs(({
                    type: 'web_bind',
                    data:{
                        jwt:store.state.jwt
                    }
                }))
                that.bindWs()
            }
            ws.onmessage = function(evt) {
                let data = JSON.parse(evt.data)
                if(data.type == "export"){
                    let d_list = data.data
                    let a = ''
                    for(let item of d_list){
                        a +=`<a src="${item.url}">${item.name}</a><br>`
                    }
                    Notification({
                            title:data.msg,
                            duration:0,
                            dangerouslyUseHTMLString: true,
                            message: a,
                            type: 'success',
                            onClick:()=>{
                                for(let item of d_list){
                                    const a = document.createElement('a');
                                    a.setAttribute('href', item.url);
                                    a.setAttribute('download', item.name);
                                    a.click();
                                }
                            }
                        }
                    )
                }else if(data.type == "web_bind"){
                    //Notification.success(data.msg)
                    if(data.code==200){
                        that.status = true
                    }
                }else if(data.type == "refresh_web"){
                    Notification({
                        title:"系统提示",
                        message:`<p>${data.msg}</p><p>时间：${data.data.date}</p>`,
                        dangerouslyUseHTMLString: true,
                        type: 'info',
                        duration:0,
                    })
                    setTimeout(()=>{
                        location.reload();
                    },10000)
                }else if(data.type == "pushChatMessage"){
                    Notification({
                        title:"消息提示",
                        message:`<p>${data.msg}</p>
                                <p>内容：${data.data.message}</p>
                        `,
                        dangerouslyUseHTMLString: true,
                        iconClass:'el-icon-chat-dot-round',
                    })
                }else if(data.type == "checkSendPush"){
                    Notification({
                        title: "测试消息",
                        message: data.msg,
                        iconClass: 'el-icon-chat-dot-round',
                    })
                }else if(data.type == "order_pay_notify"){
                    let t_data = data.data
                    Notification({
                        title: "收款提醒",
                        message: t_data.goods_desc,
                        iconClass: 'el-icon-chat-dot-round',
                    })
                }
            }
            that.ws = ws
        }
        //
        ws.onerror = function() {
            //console.log('websocket 断开: ' + err, ws.readyState)
            if (ws.readyState != 0) {
                setTimeout(() => {
                    that.initWs()
                }, 5000)
            }
        }
    },
    bindWs:function() {
        try{
            if(this.wsInterval){
                this.wsInterval.close()
                this.wsInterval = null
            }
            this.wsInterval = setInterval(()=>{
                if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
                    this.initWs()
                    return
                }
                if(this.status){
                    this.sendWs({
                        type:'ping',
                        data:{},
                        msg:'ping...'
                    })
                }else{
                    this.sendWs(({
                        type: 'web_bind',
                        data:{
                            jwt:store.state.jwt
                        }
                    }))
                }
            },60000);
        }catch (e){
            setTimeout(() => {
                this.bindWs()
            }, 300000)
        }

    },
    getWsStatus:function (){
        if(!this.ws || this.ws.readyState !== WebSocket.OPEN){
            return false
        }
        return true
    },
    //断开socked方法
    closeWs: function() {
        // 关闭定时器
        //console.log('关闭定时器')
        clearInterval(this.heartbeat)
        //console.log('关闭ws')
        this.ws.close()
    }
}
